import React from "react";
import "./Footbar.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Footer from "../../BlockElement/Footer/Footer";
import Heading from "../../TextElement/Heading/Heading";
import Container from "../../BlockElement/Container/Container";
import Paragraph from "../../TextElement/Paragraph/Paragraph";

export default function Footbar({ id }: IStructureElement) {
    return(
        <Footer id={id} className='footbar'>
            <Container className='footbar__container--contact'>
                <Heading type='h3' className='footbar__h3'>Kontakt</Heading>
                <Container className='footbar__container--details'>
                    <Container>
                        <Paragraph className='footbar__paragraph'>ks. Bogusław Jargan</Paragraph>
                        <Paragraph className='footbar__paragraph'>proboszcz@wnmp.eu</Paragraph>
                        <Paragraph className='footbar__paragraph'>885 171 967</Paragraph>
                    </Container>
                    <Container>
                        <Paragraph className='footbar__paragraph'>ks. Dawid Gajda</Paragraph>
                        <Paragraph className='footbar__paragraph'>wikary@wnmp.eu</Paragraph>
                        <Paragraph className='footbar__paragraph'>882 411 849</Paragraph>
                    </Container>
                </Container>                
            </Container>
            <Container className='footbar__container--contact'>
                <Heading type='h3' className='footbar__h3'>Adres</Heading>
                <Container className='footbar__container--details'>
                    <Container>
                        <Paragraph className='footbar__paragraph'>ul. Żeromskiego 25</Paragraph>
                        <Paragraph className='footbar__paragraph'>95-063 Rogów</Paragraph>
                    </Container>
                </Container>                
            </Container>
        </Footer>
    );
}