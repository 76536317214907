import React from "react";
import "./Navbar.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Nav from "../../BlockElement/Nav/Nav";
import { Button, Drawer, Menu } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { useSite } from "../../../hooks/useSite";

export default function Navbar({ id }: IStructureElement) {
    const { visible, mapSite, showSites, hideSites } = useSite();

    return(
        <Nav id={id} className='navbar'>
            <Button className="navbar__button" icon={<MenuOutlined />} onClick={showSites} />
            <Drawer title="Menu" placement="right" onClose={hideSites} open={visible}>
                <Menu>{mapSite()}</Menu>
            </Drawer>
        </Nav>
    );
}