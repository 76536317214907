import React from "react";
import "./Hero.scss";
import Section from "../BlockElement/Section/Section";
import Container from "../BlockElement/Container/Container";
import Heading from "../TextElement/Heading/Heading";

export default function Hero() {
    return(
        <Section className='hero'>
            <Container className='hero__h1--container'>
                <Heading type='h1' className='hero__h1'>Parafia Wniebowzięcia Najświętszej Maryi Panny w Rogowie</Heading>
            </Container>
        </Section>
    );
}