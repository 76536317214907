import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { url } from '../utils/urls';
import { Menu } from "antd";
import Link from '../components/MediaElement/Link/Link';

export const useSite = () => {
    const [sites, setSites] = useState<any[]>([]);
    const [site, setSite] = useState('');
    const [visible, setVisible] = useState(false);

    const fetchSite = useCallback(async () => {
        try {
            const response = await axios.get(`${url}/api/public/site`);
            setSites(response.data.sites);
        } catch (error: any) {
            console.log("Require: useSite() ...", error);
        }
    }, [setSites]);

    const addSite = useCallback(async (name: string) => {
        try {
            const response = await axios.post(`${url}/api/admin/site`, { name });
            setSites(response.data.sites);
        } catch (error: any) {
            console.log("Require: useSite() ...", error);
        }
    }, [setSites]);

    const deleteSite = useCallback(async (id: string) => {
        try {
            const response = await axios.delete(`${url}/api/admin/site/${id}`);
            setSites(response.data.sites);
            setSite('');
        } catch (error: any) {
            console.log("Require: useSite() ...", error);
        }
    }, [setSite, setSites]);

    const mapSite = useCallback(() => {
        try {
            return sites.map((item, index) => (
                <Menu.Item key={index} className="">
                    <Link href={item.value}>{item.label}</Link>
                </Menu.Item>
            ));
        } catch (error: any) {
            console.log("Require: useSite() ...", error);
            return null;
        }
    }, [sites]);

    const showSites = () => setVisible(true);
    const hideSites = () => setVisible(false);

    useEffect(() => {
        fetchSite();
    }, [fetchSite]);

    return { site, setSite, sites, setSites, visible, fetchSite, addSite, deleteSite, mapSite, showSites, hideSites };
};