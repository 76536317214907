import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { url } from '../utils/urls';
import Paragraph from '../components/TextElement/Paragraph/Paragraph';
import Image from '../components/MediaElement/Image/Image';

export const useText = (site: string) => {
    const [texts, setTexts] = useState<any[]>([]);
    const [text, setText] = useState('');

    const fetchText = useCallback(async (site: string) => {
        try {
            const response = await axios.get(`${url}/api/public/text/${site}`);
            setTexts(response.data.texts);
        } catch (error: any) {
            console.log("Require: useText() ...", error);
        }
    }, [setTexts]);

    const addText = useCallback(async () => {
        try {
            const response = await axios.post(`${url}/api/admin/text/${site}`, { text, type: 'text' });
            setTexts(response.data.texts);
        } catch (error: any) {
            console.log("Require: useText() ...", error);
        }
    }, [site, text, setTexts]);

    const deleteText = useCallback(async (id: string) => {
        try {
            const response = await axios.delete(`${url}/api/admin/text/${site}/${id}`);
            setTexts(response.data.texts);
            setText('');
        } catch (error: any) {
            console.log("Require: useText() ...", error);
        }
    }, [site, setText, setTexts]);

    const mapText = useCallback(() => {
        try {
            return texts.map((item, index) => {
                switch(item.type){
                  case 'text':
                    if(index === 0){ return (<h3 key={index} style={{ marginInline: 'auto', textAlign: 'center', lineHeight: 10}}>{item.text}</h3>); }
                    return (<Paragraph key={index}>{item.text}</Paragraph>);
                  case 'jpg':
                    const base64String = item.text;
                    const cleanedBase64String = base64String.includes(',') ? base64String.split(',')[1] : base64String;
                    const imageUrl = `data:image/jpeg;base64,${cleanedBase64String}`;
                    return (<Image key={index} src={imageUrl} alt='graphics' />);
                  default:
                    throw new Error('No such type to be mapped!');
                }
            });
        } catch (error: any) {
            console.log("Require: useText() ...", error);
        }
    }, [texts]);

    useEffect(() => {
        if(site){ fetchText(site); }
    }, [site, fetchText]);

    return { text, setText, texts, fetchText, addText, deleteText, mapText };
};