import { Button, List, Upload } from "antd";
import Article from "../BlockElement/Article/Article";
import Section from "../BlockElement/Section/Section";
import Heading from "../TextElement/Heading/Heading";
import './Text.scss';
import Container from "../BlockElement/Container/Container";
import { IText } from "../../types/IText";
import TextArea from "antd/es/input/TextArea";
import { url } from "../../utils/urls";
import Paragraph from "../TextElement/Paragraph/Paragraph";
import { CloudUploadOutlined } from '@ant-design/icons';

export default function Text({ site, ops, text, setText, texts, setTexts, addText, deleteText, prev }: IText) {
    switch(ops){
        case '1':
            return(
                <Article className='text'>
                    <Section className='text__section'>
                        <Heading type='h2'>Wprowadź tekst</Heading>
                        <TextArea value={text} onChange={(e) => setText(e.target.value)} style={{height: '20dvh', background: '#f9f9f9'}}/>
                        <Container>
                            <Button htmlType='button' style={{ marginTop: 10 }} onClick={() => prev()}>Cofnij</Button>
                            <Button htmlType='button' type="primary" style={{ marginTop: 10, marginLeft: 20 }} onClick={() => addText()}>Dodaj</Button>
                        </Container>
                    </Section>
                    <Section className='text__section'>
                        <Heading type='h2'>Prześlij plik</Heading>
                        <Upload.Dragger
                            name="files"
                            action={`${url}/api/admin/text/${site}/upload`}  
                            headers={{ Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }}                
                            className='text__upload'
                        >
                            <CloudUploadOutlined className='text__icon'/>
                            <Paragraph className="ant-upload-hint">Click or drag file to this area to upload</Paragraph>
                        </Upload.Dragger>
                    </Section>
                </Article>
            );
        case '2':
            return(
                <Article className='text--delete'>
                    <List
                        style={{width: '100%', height: '30dvh', overflow: 'auto' }}
                        bordered
                        dataSource={texts}
                        renderItem={(item) => (
                            <List.Item actions={[<Button type="primary" danger onClick={() => deleteText(item.id)}>Usuń</Button>]}>
                                <div style={{maxWidth: '80%', maxHeight: '4dvh', overflow: "hidden"}}>{item.text}</div>
                            </List.Item>
                        )}
                    />
                    <Container><Button htmlType='button' style={{ marginTop: 10 }} onClick={() => prev()}>Cofnij</Button></Container>
                </Article>
            );
        default:
            return <Article className='text'></Article>;
    }
}