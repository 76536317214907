import { Button, Select } from "antd";
import Article from "../BlockElement/Article/Article";
import Section from "../BlockElement/Section/Section";
import Heading from "../TextElement/Heading/Heading";
import './Operation.scss';
import Container from "../BlockElement/Container/Container";
import { operations } from "../../utils/dashboard";

export default function Operation(props: { val: string, setVal: (value: any) => void, prev: () => void, next: () => void}) {

    return(
        <Article className='operation'>
            <Section className='operation__section'>
                <Heading type='h2'>Wybierz operację</Heading>
                <Select options={operations} onChange={(value) => props.setVal(value)} style={{width: 300}}/>
                <Container>
                    <Button htmlType='button' style={{ marginTop: 10 }} onClick={() => props.prev()}>Cofnij</Button>
                    {props.val && <Button htmlType='button' type='primary' style={{ marginTop: 10, marginLeft: 20 }} onClick={() => props.next()}>Dalej</Button>}
                </Container>
            </Section>
        </Article>
    );
}