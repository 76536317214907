import React from "react";
import './Authorization.scss';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthorization } from "../../hooks/useAuthorization";

export default function Authorization(){
    const { loading, error, signin } = useAuthorization(true);
    
    return(
        <Form
            name = "authorization"
            className = "authorization"
            onFinish={signin}
        >
            <Form.Item
                name="email"
                className='authorization__item'
                rules={[{ required: true, message: 'Please enter the email!' }]}
                validateStatus={error ? "error" : ""}
                help={error}
                hasFeedback
            >
                <Input prefix={<UserOutlined />} placeholder="Email" className='authorization__input' />
            </Form.Item>

            <Form.Item
                name="password"
                className='authorization__item'
                rules={[{ required: true, message: 'Please enter the password!' }]}
                validateStatus={error ? "error" : ""}
                help={error}
                hasFeedback
            >
                <Input prefix={<LockOutlined />} type="password" placeholder="Password" className='authorization__input' />
            </Form.Item>

            <Form.Item className='authorization__item'>
                <Button type="primary" htmlType="submit" className="authorization__button" loading={loading}>Zaloguj</Button>
            </Form.Item>
        </Form>
    );
}